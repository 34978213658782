.header-container-history {
  background-color: #ECECEC; /* Light background for distinction */
  border-bottom: 1px solid #ddd; /* Subtle separation line */
  padding: 0px 10px; /* Adjust padding specific to header */
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left-display-history {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 7px;
  width: 33.33%;
}

.header-left-top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  width: 100%;
}
