.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  /* padding-left: 10px;
  padding-right: 5px; */
  height: 100vh;
  z-index: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.fullscreen-header {
  height: 7vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 10px;
}

.fullscreen-close-button {
  width: 50px;
  background-color: white !important;
  color: black !important;
  border: 1px solid lightgrey !important;
  border-radius: 15px !important;
}

.fullscreen-body {
  height: 93vh;
  padding: 0 10px;
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: 1.25fr 5.5fr 1.25fr; /* Left, Middle, Right sections */
  gap: 0; /* No spacing between columns */
}

/* Responsive styling */
@media (max-width: 768px) {
  .fullscreen-body {
    grid-template-columns: 1fr 4fr 1fr;
  }
}

/* ************* LEFT VERTICAL CONTAINER ************* */
.left-vertical-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start horizontally */
  justify-content: flex-start; /* Align items to the top vertically */
  border-radius: 10px;
  height: 100%;
  background-color: #3a3a3a;
  color: white;
  padding: 10px;
  gap: 10px;
  box-sizing: border-box;
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  overflow-x: hidden;
  max-width: 220px;
}

/* Ensure text wraps and doesn't cause horizontal overflow */
.left-vertical-container p,
.left-vertical-container div,
.left-vertical-container span {
  white-space: normal; /* Allows text to wrap */
  word-wrap: break-word; /* Breaks long words */
  overflow-wrap: break-word; /* Modern equivalent of word-wrap */
}

.bold {
  font-weight: 600;
}

.no-bold {
  font-weight: normal;
}

.font-Xxl {
  font-size: 1.55rem;
}

.font-xl {
  font-size: 1.35rem;
}

.font-l {
  font-size: 1.15rem;
}

.font-m {
  font-size: 1.1rem;
}

.font-s {
  font-size: 0.85rem;
}

/* ************* MIDDLE ************* */
.middle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Ensures space between title, body, and bottom buttons */
  border-radius: 10px;
  height: 95%;
  background-color: #fff;
  padding: 0 10px; /* Optional padding for inner spacing */
}

.middle-container-title {
  width: 80%;
  max-width: 650px;
  /* height: 2vh; */
  /* text-align:left; */
  font-size: 1.125rem;
  /* font-weight: 600; */
  /* border-bottom: 1px solid lightgrey; Optional: Adds a separator for the title */
}

/* ************* MIDDLE Body ************* */

.middle-container-body {
  height: 70vh;
  width: 80%;
  max-width: 650px;
  padding: 15px; /* Add padding to the container */
  background-color: rgb(
    243,
    240,
    240
  ); /* Light background for better readability */
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between chat messages */
  overflow-y: auto; /* Allow scrolling for long chats */
  box-sizing: border-box; /* Ensure padding doesn't affect width calculations */
}

.middle-container-body-history{
  height: 60vh;
  width: 80%;
  max-width: 650px;
  padding: 15px; /* Add padding to the container */
  background-color: rgb(
    243,
    240,
    240
  ); /* Light background for better readability */
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between chat messages */
  overflow-y: auto; /* Allow scrolling for long chats */
  box-sizing: border-box; /* Ensure padding doesn't affect width calculations */
}

/* Base style for chat messages */
.chat-message {
  max-width: 70%; /* Limit message width */
  padding: 10px;
  border-radius: 15px;
  font-size: 1.1rem;
  word-wrap: break-word; /* Break long words if needed */
}

/* Style for received messages */
.chat-received {
  background-color: #fff;
  align-self: flex-start; /* Align to the far left */
  border-top-left-radius: 0;
}

/* Style for sent messages */
.chat-sent {
  color: #fff;
  background-color: #459af4;
  align-self: flex-end; /* Align to the far right */
  border-top-right-radius: 0;
}

.image-message{
  background-color: "green";
}

/* *********************************** */

.moderation-details{
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start horizontally */
  justify-content: flex-start; /* Align items to the top vertically */
  border-radius: 10px;
  width:80%;
  gap:15px;
  /* margin-top: 10px; */
  color: black;
}

.detail-row {
  display: flex;
  align-items: center;
}

.moderation-details strong {
  font-size: 20px;
}

.moderation-details p,
.moderation-details span {
  margin: 0; /* Remove default margins */
  font-size: 16px; /* Adjust as needed */
}

.middle-container-buttons {
  display: flex;
  justify-content: space-around; /* Aligns the buttons horizontally with equal spacing */
  width: 650px;
  height: 9.3vh;
  padding: 10px 0;
  /* border-top: 1px solid lightgrey; Optional: Adds a separator for the buttons */
}

.middle-container-buttons button {
  flex-grow: 1; /* Makes buttons evenly distributed */
  margin: 0 5px; /* Adds spacing between buttons */
  max-width: 150px; /* Optional: Limit button width if desired */
}

/* ************* RIGHT VERTICAL CONTAINER ************* */
.right-vertical-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start horizontally */
  justify-content: flex-start; /* Align items to the top vertically */
  border-radius: 10px;
  height: 100%;
  background-color: #3a3a3a;
  color: white;
  padding: 10px;
  gap: 10px;
  box-sizing: border-box;
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  max-width: 220px;
}

/* Ensure text wraps and doesn't cause horizontal overflow */
.right-vertical-container p,
.right-vertical-container div,
.right-vertical-container span {
  white-space: normal; /* Allows text to wrap */
  word-wrap: break-word; /* Breaks long words */
  overflow-wrap: break-word; /* Modern equivalent of word-wrap */
}

.image-circle {
  max-width: 100%;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin: 25px auto;
  object-fit: cover;
  display: block;
  font-size: 12px;
}

.w-100 {
  width: 100%;
}

.w-100 .p-inputtext {
  width: 100%;
}

.w-100 h4 {
  text-align: left;
}

.table-chat {
  border: 1px solid #000;
  width: 100%;
  padding: 10px;
}

.table-chat .user-get .user-get-text {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.table-chat .user-get .user-get-text h4,
.table-chat .user-get .user-get-text p {
  margin: 0;
  word-break: break-all;
}

.table-chat .user-get .user-get-text .w-fix {
  width: 100px;
}
