.header-container {
  background-color: #ECECEC; /* Light background for distinction */
  border-bottom: 1px solid #ddd; /* Subtle separation line */
  padding: 0px 10px; /* Adjust padding specific to header */
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-header-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 130px;
  height: 100px;
  width: 33.33%;
}

.header-left-display {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  width: 33.33%;
}

.header-right-display {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8px;
  gap: 7px;
  width: 33.33%;
}

.header-right-display-filters{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
