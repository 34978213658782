.body-container {
  display: grid;
  grid-template-columns: repeat(5, minmax(180px, 1fr));
  gap: 30px;
  padding: 30px 120px;
  background-color: #ffffff;
}


/* ******************* Card ********************** */

.card-item {
  border: 1px solid #ddd; /* Optional border */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: white;
  aspect-ratio: 3 / 4.75;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  height: 100%;
}

.card-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
