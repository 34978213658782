.user-search {
  width: 80%;
  margin: auto;
  color: #495057;
}

.user-search .title {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  /* max-height: 5vh; */
  /* border: 1px solid black; */
}
.user-search > * > * {
  margin: 0px auto;
}

.filter_search_button{
  background-color: #5555AA;
  color:white;
  border-radius: 5px;
  /* border: 2px solid lightgrey; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.filter_search_button.active{
  background-color: white;
  color:black;
  border: 2px solid lightgrey;
}

.user-search button {
  width: 50%;
  background: #55a;
  color: white;
}

.results {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  padding-bottom: 50px;
}
.result {
  flex-grow: 1;
  width: 33%;
  padding: 10px;
  margin: 0;
}
.result .block {
  height: 95vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.results .result img {
  max-width: 100%;
  width: 100%;
  max-height: 90vh;
}
.image-results .result {
  flex-grow: 0;
}
.results .post-text {
  background: #eee;
  border-radius: 2px;
  padding: 10px;
  /* margin-top: -20px; */
}

.field {
  padding: 10px;
  background: #f5f5f5;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.field:nth-child(even) {
  background-color: #eeeeee;
}

.field .label {
  margin-right: 10px;
  font-weight: bold;
}

.field .value {
  word-wrap: break-word;
  max-width: 100%;
}

.red {
  color: red;
  text-align: center;
  width: 100%;
}
.moderation-controls > .block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* padding: 20px; */
  gap:5px
}

.control-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #eeeeee;
  margin-left: 5px;
  height: 40px;
  cursor: pointer;
  padding: 10px;
}
.control-button:hover,
.gray {
  background: #e0e0e0;
}
.control-button:nth-child(odd) {
  background: #e57373;
  color: white;
  margin-left: 20px;
}

.control-button:nth-child(odd):hover {
  background-color: #ef5350;
}
.neutral {
  background-color: none;
  display: flex;
  justify-content: space-around;
  padding: 0px;
}
.neutral {
  margin-left: 20px;
  color: #495057;
}

.neutral .control-button {
  background-color: #eeeeee;
  color: #495057;
}
.neutral .control-button:hover {
  background-color: #e0e0e0;
}

.search {
  padding: 10px;
}

.search .p-inputtext {
  width: 100%;
}
.search .p-button {
  width: 100%;
  padding: 10px;
}
